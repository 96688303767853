import { FunctionComponent } from "react";
import { PlausibleSetup } from "./Plausible";

export const AnalyticsProvider: FunctionComponent = ({ children }) => {
  return (
    <>
      <PlausibleSetup />
      {children}
    </>
  );
};
