import { createContext, ReactNode, useContext, useState } from "react";
import PreviewBanner from "./PreviewBanner";

type State = {
  isOn: boolean;
  toggle: () => void;
  setPreviewMode: (isOn: boolean) => void;
};

const initState: State = {
  isOn: false,
  toggle: () => null,
  setPreviewMode: () => null,
};

const Context = createContext<State>(initState);

export const usePreviewContext = () => useContext(Context);
interface Props {
  children: ReactNode;
}

export const PreviewContextProvider = (props: Props) => {
  const [previewMode, setPreviewMode] = useState(initState.isOn);

  return (
    <Context.Provider
      value={{
        isOn: previewMode,
        toggle: () => setPreviewMode((s) => !s),
        setPreviewMode: (isOn) => setPreviewMode(isOn),
      }}
    >
      <PreviewBanner />
      {props.children}
    </Context.Provider>
  );
};
