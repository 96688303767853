import { BiblioteksentralenProvider } from "@biblioteksentralen/js-utils";
import { AppProps } from "next/app";
import React, { ReactNode } from "react";
import { useMount } from "react-use";
import { EditInSanityContextProvider } from "../components/editInSanity/EditInSanityContext";
import { PreviewContextProvider } from "../components/preview/previewContext";
import { SOMEPreview } from "../components/preview/SOMEPreview";
import Testenvironmentbanner from "../components/TestEnvironmentBanner";
import { AnalyticsProvider } from "../utils/analytics/AnalyticsProvider";
import { logVercelInfo } from "../utils/logVercelInfo";

function Providers(props: { children: ReactNode }) {
  return (
    <AnalyticsProvider>
      <BiblioteksentralenProvider>
        <PreviewContextProvider>
          <EditInSanityContextProvider>{props.children}</EditInSanityContextProvider>
        </PreviewContextProvider>
      </BiblioteksentralenProvider>
    </AnalyticsProvider>
  );
}

function App({ Component, pageProps }: AppProps) {
  useMount(() => logVercelInfo());

  return (
    <Providers>
      <Testenvironmentbanner />
      <Component {...pageProps} />
      <SOMEPreview />
    </Providers>
  );
}

export default App;
